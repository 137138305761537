import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
    strict: false,
    plugins: [vuexLocal.plugin],
    state: {
      userInfo: null,
      cityData: {
        id: 140100,
        name: 'w我的市'
      },
    },
    getters: {
      getUserInfo(state){
        return state.userInfo
      },
      getToken(state){
        return state.userInfo && state.userInfo.token || ''
      },
      getCityData(state){
        return state.cityData
      }
    },
    mutations: {
      setUserInfo(state,userInfo){
        state.userInfo = userInfo
      },
      clearUserInfo(state){
        state.userInfo = null
      },
      setCityData(state,cityData){
        state.cityData = cityData
      }
    },
    actions:{
      login({commit},user){
        commit('setUserInfo',user)
      },
      logout({ commit }) {
        commit('clearUserInfo');
      },
      changeCity({commit},cityData){
        commit('setCityData',cityData)
      }
    }
})

export default store