import request from '@/utils/request'

export function send(data) { //获取验证码
	return request({
		url: '/api/user/send',
		method: 'post',
		data,
	})
}

export function mobilelogin(data) { //手机验证码登录
	return request({
		url: '/api/user/mobilelogin',
		method: 'post',
		data,
	})
}

export function login(data) { //密码登录
	return request({
		url: '/api/user/login',
		method: 'post',
		data,
	})
}

export function getpassword(data) { //找回密码 
	return request({
		url: '/api/user/getpassword',
		method: 'post',
		data,
	})
}

export function bindthird(data) { //绑定手机号   code:1
	return request({
		url: '/web/index/bindthird',
		method: 'post',
		data,
	})
}

export function loginuser(data) { //绑定手机号   code:5
	return request({
		url: '/web/index/loginuser',
		method: 'post',
		data,
	})
}
