import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI  from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import store from './store/index.js'
import './style/color.scss'
import './style/flex.scss'

import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '9fd18ed34fdac253d897e8d2f4a43b49', 
  // plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.Geolocation', 'AMap.Geocoder','AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
});

import hevueImgPreview from 'hevue-img-preview'
Vue.use(hevueImgPreview)

//分辨率
import './utils/rem.js'

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video  

Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.use(ElementUI)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'IQHf61bBYmpSozQV21EAtCHXwJBzpfhR'
})

new Vue({
	render: h => h(App),
	router,
  store
}).$mount('#app')
