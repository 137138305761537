import Vue from "vue"
import VueRouter from 'vue-router' 
Vue.use(VueRouter)

//解决连续多次点击跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
    routes:[
        {
            path: '/home',
            name:'home',
            component: () => import('@/views/home/index'),
        },
        {
            path: '/',
            name:'home',
            redirect: '/home'
        },
        {
          path: '/my',
          name: 'my',
          component: ()=>import('../views/my'),
          redirect:'/my/managework',
          children:[
            {
              path: '/my/managework',
              name: 'managework',
              component: ()=>import('../views/my/managework'),
              meta:{name:'管理招工'}
            },
            {
              path: '/my/information',
              name: 'information',
              component: ()=>import('../views/my/information'),
              meta:{name:'个人资料'}
            },
            {
              path: '/my/mycard',
              name: 'mycard',
              component: ()=>import('../views/my/mycard'),
              meta:{name:'我的名片'}
            },
            {
              path: '/my/postJob',
              name: 'postJob',
              component: ()=>import('../views/my/postJob'),
              meta:{name:'发布招工信息'}
            },
            {
              path: '/my/lookme',
              name: 'lookme',
              component: ()=>import('../views/my/lookme'),
              meta:{name:'谁看过我'}
            },
            {
              path: '/my/realname',
              name: 'realname',
              component: ()=>import('../views/my/realname'),
              meta:{name:'认证管理'}
            },
            {
              path: '/my/contact',
              name: 'contact',
              component: ()=>import('../views/my/contact'),
              meta:{name:'联系记录'}
            },
            {
              path: '/my/help',
              name: 'help',
              component: ()=>import('../views/my/help'),
              meta:{name:'帮助中心'}
            }
          ]
        },
        { //谁看过我详情
          path: '/my/lookmeDtl',
          name: 'lookmeDtl',
          component: ()=>import('../views/my/lookmeDtl'),
        },
        {//下载APP
          path: '/download',
          name: 'download',
          component: ()=>import('../views/download'),
        },
        {//企业介绍
          path: '/introduce',
          name: 'introduce',
          component: ()=>import('../views/introduce'),
        },
        {//新闻资讯
          path: '/news',
          name: 'news',
          component: ()=>import('../views/news'),
        },
        {//新闻详情
          path: '/newsDtl',
          name: 'news',
          component: ()=>import('../views/news/newsDtl'),
        },
        {//招标信息
          path: '/tender',
          name: 'tender',
          component: ()=>import('../views/tender'),
        },
        {//招标详情
          path: '/tenderDtl',
          name: 'tender',
          component: ()=>import('../views/tender/tenderDtl'),
        },
        {//云培训
          path: '/clouding',
          name: 'clouding',
          component: ()=>import('../views/clouding'), 
        },
        {//云培训详情
          path: '/cloudingDtl',
          name: 'clouding',
          component: ()=>import('../views/clouding/cloudingDtl'), 
        },
        {//记工记账
          path: '/work',
          name: 'work',
          component: ()=>import('../views/work'), 
        },
        {//切换城市
          path: '/home/changeCity',
          name: 'changeCity',
          component: ()=>import('../views/home/changeCity'), 
        },
        {//招人才
          path: '/recruit',
          name: 'recruit',
          component: ()=>import('../views/recruit'), 
        },
        {//招人才详情
          path: '/recruitDtl',
          name: 'recruit',
          component: ()=>import('../views/recruit/recruitDtl'), 
        },
        {//找工作
          path: '/findWork',
          name: 'findWork',
          component: ()=>import('../views/findWork'), 
        },
        {//找工作详情
          path: '/findDtl',
          name: 'findWork',
          component: ()=>import('../views/findWork/findDtl'), 
        },
        

    ]
})


