<template>
  <div>
     <el-dialog 
        class="dialog"
        :visible.sync="config.isshow" 
        width="40%" 
        :modal-append-to-body='false'
      >
      <div class="logo">
		    <img src="../assets/log.png" alt="">
      </div>
      <!-- 登录 --> 
      <div v-if="types == 1">
        <div class="content">
          <p>登录/注册</p>
          <el-form ref="form" status-icon :rules="rules" :model="form"  style="width: 80%;padding-top: 0.5rem;">
            <div class="grey">
              <span v-for="item in list" :key="item.id" @click="loginClick(item)" :class="item.id == id ? 'aa' : 'bb'">{{ item.name }}</span>
            </div>
            <el-form-item prop="phone">
              <el-input  v-model.number="form.phone" maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <!-- 短信登录：验证码 -->
            <el-form-item prop="verify" v-if="id == 1">
              <div class="bind_code margin_top">
                <el-input  class="bind_code_input code" v-model="form.verify" type="text" placeholder="请输入验证码"/> 
                <el-button  @click.native.prevent="bindforgetSendCode" class="bind_code_gain" :disabled="disabled">{{ btnText }}</el-button>
              </div>
            </el-form-item>
            <!-- 密码登录：密码 -->
            <el-form-item  prop="pass" v-if="id == 2">
              <el-input type="password" v-model="form.pass" maxlength="6" autocomplete="off" placeholder="请输入密码"></el-input>
            </el-form-item>
            <div>
              <el-checkbox v-model="checked" @change="checkvalue" style="color: #9E9E9E;">已阅读并同意 <span @click="showprivacy" style="color: #4FBBEF;">  《隐私政策》</span> <span @click="showAgreement" style="color: #4FBBEF;">  《服务协议》</span></el-checkbox>
            </div>
          </el-form>
        </div>
        <div class="login">
          <el-button type="primary" @click="submitForm('form')">登录/注册</el-button>
        </div>
        <div class="icon a" v-if="id == 2">
          <el-checkbox v-model="isRemeber" @change="remeber">记住密码</el-checkbox>
          <div @click="forget">忘记密码</div>
        </div>
        <div class="icon" v-if="id == 1" style="padding-top: 3.5rem;">
          <img src="../assets/wx.png" alt="" @click="clickwx">
          <img src="../assets/dy.png" alt="" @click="clickdy">
          <img src="../assets/zfb.png" alt="" @click="clickzfb">
        </div>
        <div class="icon" v-if="id == 2">
          <img src="../assets/wx.png" alt="" @click="clickwx">
          <img src="../assets/dy.png" alt="" @click="clickdy">
          <img src="../assets/zfb.png" alt="" @click="clickzfb">
        </div>
      </div>
      <!-- 忘记密码 -->
      <div v-if="types == 2">
        <i class="el-icon-arrow-left back" @click="types = 1"></i>
        <div class="content">
          <span class="password">找回密码</span>
          <el-form ref="form" status-icon :rules="rules" :model="form"  style="width: 80%;padding-top: 0.5rem;">
            <el-form-item prop="phone">
              <el-input  v-model.number="form.phone" maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="verify">
              <div class="bind_code margin_top">
                <el-input  class="bind_code_input code" v-model="form.verify" type="text" placeholder="请输入验证码"/> 
                <el-button  @click.native.prevent="bindforgetSendCode" class="bind_code_gain" :disabled="disabled">{{ btnText }}</el-button>
              </div>
            </el-form-item>
            <el-form-item  prop="sixpassword">
              <el-input type="password" v-model="form.sixpassword" maxlength="6" autocomplete="off" placeholder="请设置6位数密码"></el-input>
            </el-form-item>
            <div>
              <el-checkbox v-model="checked" @change="checkvalue" style="color: #9E9E9E;">已阅读并同意 <span @click="showprivacy" style="color: #4FBBEF;">《隐私政策》</span> <span @click="showAgreement" style="color: #4FBBEF;">《服务协议》</span> </el-checkbox>
            </div>
          </el-form>
        </div>
        <div class="login">
          <el-button type="primary" @click="submitForm('form')">登录</el-button>
        </div>
        <div class="icon">
          <img src="../assets/wx.png" alt="" @click="clickwx">
          <img src="../assets/dy.png" alt="" @click="clickdy">
          <img src="../assets/zfb.png" alt="" @click="clickzfb">
        </div>
      </div>
      <!-- 授权登录 -->
      <div v-if="types == 3">
        <i class="el-icon-arrow-left back" @click="types = 1"></i>
        <div class="content">
          <span class="password">授权登录</span>
          <wxlogin
            :appid="appid"
            :scope="'snsapi_login'" 
            :theme="'white'"
            redirect_uri="https://dslw.dsecc.cn/web/index/codeinfo"
            :href='bast64css'
            v-if="name == '微信'">
          </wxlogin>
          <div class="wxdown" v-if="name == '微信'">请使用{{name}}扫描二维码登录“德胜劳务”</div>
          <div class="down" v-if="name != '微信'">请使用{{name}}扫描二维码登录“德胜劳务”</div>
        </div>
      </div>
      <!-- 绑定手机号 -->
      <div v-if="types == 4">
        <i class="el-icon-arrow-left back" @click="types = 1"></i>
        <div class="content">
          <span class="password">绑定手机号</span>
          <el-form ref="form" status-icon :rules="rules" :model="form" style="width: 80%;padding-top: 3rem;">
            <!-- <el-form-item  prop="name">
              <el-input v-model="form.name" autocomplete="off" placeholder="请输入真实姓名"></el-input>
            </el-form-item> -->
            <el-form-item prop="phone">
              <el-input  v-model.number="form.phone" maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="verify">
              <div class="bind_code margin_top">
                <el-input  class="bind_code_input code" v-model="form.verify" type="text" placeholder="请输入验证码"/> 
                <el-button  @click.native.prevent="bindforgetSendCode" class="bind_code_gain" :disabled="disabled">{{ btnText }}</el-button>
              </div>
            </el-form-item>
            <div>
              <el-checkbox v-model="checked" @change="checkvalue" style="color: #9E9E9E;">已阅读并同意 <span @click="showprivacy" style="color: #4FBBEF;">《隐私政策》</span><span @click="showAgreement" style="color: #4FBBEF;">《服务协议》</span> </el-checkbox>
            </div>
          </el-form>
        </div>
        <div class="login">
          <el-button type="primary" @click="submitForm('form')">登录</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- <el-dialog 
        :visible.sync="ZFBdialog" 
        width="40%" 
        :modal-append-to-body='false'
      >
        <iframe :src="iSrc" style="width: 100%;height: 30rem;margin-top: 0.5rem;"> </iframe>
    </el-dialog> -->
  </div>
 
</template>

<script>
import { send,mobilelogin,login,getpassword,bindthird,loginuser} from "../api/login";
import { pcwebconfig } from "../api/introduce";
import wxlogin from 'vue-wxlogin'

export default {
  props:{
    config:{
      type:Object,
      default:{
        isshow:false
      },
    },
  },
  name: 'wxLogin',
  components: { wxlogin },
  data() {
    var validatePass = (rule, value, callback) => {
      console.log(value)
      if (value === '' || value == undefined) {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass');
        }
        callback();
      }
    };
    return {
      list:[
        {id:1,name:'短信登录'},
        {id:2,name:'密码登录'},
      ],
      id:1,
      form: {
        phone:'',
        verify:'',
        pass: '',
        sixpassword:'',
        name:''
      },
      rules: {
        name: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        phone: [
          { required: true,message: "请输入手机号",  trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        verify: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        sixpassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            type: 'string',
            message: '请输入不包含空格的字符',
            trigger: 'blur',
            transform(value) {
              if (value && value.indexOf(' ') === -1) {
                return value
              } else {
                return false
              }
            }
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /(?=.*\d).{6}/
              if (!passwordreg.test(value)) {
                callback(new Error('请输入6位'))
              }else{
                callback()
              }
            } 
          }
        ],
      },
      type: "",
      btnText: "获取验证码",
      disabled: false,
      checked: false,   //勾选框
      isRemeber: false,  //默认不记住密码
      types:1,   // 1：登录/注册   2：找回密码   3：授权登录    4:绑定手机号
      event:'mobilelogin',  //mobilelogin短信登录 resetpwd重置密码
      name:'微信',
      url:'',
      //
      bast64css: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEycmVtO2hlaWdodDoxMnJlbX0=',
      appid: 'wx64b6e4193245d9c6', // 提供
      newfromtype:1,  //1是pc微信 2是app微信 3是pc抖音 4是app抖音 5是pc支付宝 6是app支付宝
      mydata:'',
    };
  },
  created(){
    this.getcode()
  },
  watch:{
    'name':{
      deep:true,
      handler(newVal){
        if(newVal){
          this.name = newVal
        }else{
          this.name = '微信'
        }
        console.log(this.name)
        if(this.name == '微信'){
          this.newfromtype = 1
        }
        if(this.name == '抖音'){
          this.newfromtype = 3
        }
        if(this.name == '支付宝'){
          this.newfromtype = 5
        }
        console.log(this.newfromtype)
        
      }
    },
  },
  mounted() {
    if (localStorage.getItem('rememberMe') === 'true') {
      this.form.phone = localStorage.getItem('phone') || '';
      this.form.pass = localStorage.getItem('password') || '';
      this.isRemeber = true
    }
    if (window.location.href.indexOf('code') >= 0) {
        let url = window.location.href.split('?')[1];
        //code
        let codeUrl = url.split('&')[0]
        let code = codeUrl.split('=')[1]
        //data
        let dataUrl = url.split('&')[1]
        if(url.split('&')[1]){
          let data = dataUrl.split('=')[1]
          this.mydata = data
          console.log(this.mydata)
        }
        if(code == 1){
          this.types = 4
          this.newfromtype = 1
        }
        if(code == 7){
          this.types = 4
          this.newfromtype = 5
        }
        if(code == 13){ 
          this.types = 4
          this.newfromtype = 3
        }
        let loginpc= localStorage.getItem('loginpc');
        if(code == 5 || code == 10 || code == 15){
            if(loginpc!='true'){
              localStorage.setItem('loginpc','true');
              this.fivecode()
            }
        }
    }
  },
  methods: {
    fivecode(){
      loginuser({
        mydata:this.mydata
      }).then(res => {
        if(res.code == 1){
          this.$message.success(res.msg)
          this.$store.dispatch('login', res.data);
          this.config.isshow = false
          this.$router.push({path:'/'})
          window.location.reload();
        }else{
          this.$message.warning(res.msg)
        }
      })
    },
    getcode(){
      pcwebconfig().then(res => {
        if(res.code == 1){
          this.url = res.data.appdownload.value
        }
      })
    },
    showprivacy(){
      window.open('https://dslw.dsecc.cn/index/index/privacy'); 
    },
    showAgreement(){
      window.open('https://dslw.dsecc.cn/index/index/userprotect'); 
    },
    clearValues(){
      if(this.$refs['form'] !== undefined) {
        this.$refs['form'].resetFields();
      }
    },
    clickwx(){
      this.types = 3
      this.name = '微信'
    },
    clickdy(){
      this.name = '抖音'
      this.config.isshow = false
      window.open('https://open.douyin.com/platform/oauth/connect?client_key=awd8r0uokoltpc50&response_type=code&scope=user_info&redirect_uri=https://dslw.dsecc.cn/web/index/dylogin&state=STATE')
      // this.DYdialog = true
      // this.DSrc = 'https://open.douyin.com/platform/oauth/connect?client_key=awd8r0uokoltpc50&response_type=code&scope=user_info&redirect_uri=https://dslw.dsecc.cn/web/index/dylogin&state=STATE'
      // this.types = 3
    },
    clickzfb(){
      this.name = '支付宝'
      this.config.isshow = false
      window.open('https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021004143686046&scope=auth_user&redirect_uri=https://dslw.dsecc.cn/web/alipay/getUserInfo&state=init')
    },
    forget(){
      this.types = 2
      this.event = 'resetpwd'
    },
    loginClick(item){
      this.id = item.id
      if(this.id == 1){
        this.event = 'mobilelogin'
      }
      console.log(this.id)
      if(this.$refs['form'] !== undefined) {
        // this.$refs['form'].resetFields();
        this.form.verify = ''
      }
    },
    bindforgetSendCode() {
      this.$refs.form.validateField("phone", (errorMessage) => {
        console.log(errorMessage)
        if(this.phone == ''){
          return false;
        }
        if (!errorMessage) {
          this.disabled = true;
          send({ 
            mobile:this.form.phone,
            event:this.event
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
            }else{
              this.$message.warning(res.msg)
            }
          })
          setTimeout(() => {
            this.doLoop(60);
          }, 500);
        } else {
          return false;
        }
      });
    },
    doLoop (seconds) { // 手机验证码的倒计时
      seconds = seconds ? seconds : 60;
      this.btnText = seconds + "s后获取";
      // this.code = code
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + "s后获取";
          --seconds;
        } else {
          this.btnText = "获取验证码";
          this.disabled = false;
          clearInterval(countdown);
        }
      }, 1000);
    },
    checkvalue(e){  //勾选框
      this.checked = e
    },
    remeber(e){
      this.isRemeber = e
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.checked == true){
            if(this.types == 1){  //登录
              if(this.id == 1){
                mobilelogin({
                  mobile:this.form.phone,
                  code:this.form.verify,
                  from:2
                }).then(res => {
                  if(res.code == 1){
                    console.log(res)
                    this.$message.success(res.msg)
                    this.$store.dispatch('login', res.data);
                    this.config.isshow = false
                    console.log(this.$store.state.userInfo)
                    window.location.reload();
                    // this.$router.push({path:'/'})
                    this.$emit('login-success', { name: res.data.username, avatar: res.data.avatar });
                  }else{
                    this.$message.warning(res.msg)
                  }
                })
              }
              if(this.id == 2){
                login({
                  account:this.form.phone,
                  password:this.form.pass,
                }).then(res => {
                  if(res.code == 1){
                    if (this.isRemeber) {
                      localStorage.setItem('phone', this.form.phone);
                      localStorage.setItem('password', this.form.pass);
                      localStorage.setItem('rememberMe', 'true');
                    } else {
                      localStorage.removeItem('username');
                      localStorage.removeItem('password');
                      localStorage.setItem('rememberMe', 'false');
                    }
                    this.$message.success(res.msg)
                    this.$store.dispatch('login', res.data);
                    this.config.isshow = false
                    console.log(this.$store.state.userInfo)
                    window.location.reload();
                    // this.$router.push({path:'/'})
                    this.$emit('login-success', { name: res.data.username, avatar: res.data.avatar });
                  }else{
                    this.$message.warning(res.msg)
                  }
                })
              }
            }
            if(this.types == 2){  //忘记密码
              getpassword({
                mobile:this.form.phone,
                password:this.form.sixpassword,
                code:this.form.verify
              }).then(res => {
                if(res.code == 1){
                  this.$message.success(res.msg)
                  this.types = 1
                  this.id = 2
                }else{
                  this.$message.warning(res.msg)
                }
              })
            }
            if(this.types == 4){  //授权绑定手机号
              bindthird({
                mobile:this.form.phone,
                // name:this.form.name,
                code:this.form.verify,
                newfromtype:this.newfromtype,
                mydata:this.mydata
              }).then(res => {
                if(res.code == 1){
                  console.log(res)
                  this.$message.success(res.msg)
                  this.$store.dispatch('login', res.data);
                  this.config.isshow = false
                  console.log(this.$store.state.userInfo)
                  this.$router.push({path:'/'})
                  window.location.reload();

                }else{
                  this.$message.warning(res.msg)
                }
              })
            }
          }else{
            this.$message.warning('请先勾选阅读框')
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
@import "../style/padding";

::v-deep .el-dialog {
  border-radius: 1.1rem;
  height: 35rem;
  border-radius: 1rem;
  background-image: url(../assets/bg.png);
  background-size: 100% 100%;
  position: relative;
}
::v-deep .el-dialog__header{
  padding: 0;
}
.logo{
  position: absolute;
  left: 2rem;
  top: 3rem;
  img{
    width: 12rem;
    height: 4rem;
  }
}
.aa{
  background: #fff;
  color: #4FBBEF;
  border-radius: 0.3rem;
}
.bb{
  color: #6A6A6A;
}
.back{
  padding-left: 18rem;
  font-size: 1.8rem;
}
.content{
  padding-left: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .down{
    font-size: 0.9rem;
    font-weight: bold;
    color: #444444;
    padding-top: 4rem;
  }
  .password{
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 1.5rem;
  }
 
  p{
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  .grey{
    height: 2.8rem;
    background: #F7F7F7;
    display: flex;
    margin-bottom: 1rem;
    span{
      height: 80%;
      display: flex;
      align-items: center;
      width: 45%;
      justify-content: center;
      margin: auto;
      border-radius: 0.3rem;
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
  .bind_code {
    .el-button {
      border: 0;
      padding: 0;
      border-radius: 0;
    }
    .bind_code_gain {
      position: absolute;
      top: calc(50% - 9px);
      right: 10px;
      font-size: 14px;
      color: #4FBBEF;
      line-height: 18px;
      padding-left: 10px;
      border-left: 1px solid #d8d8d8;
    }
  } 
}
.login{
  padding-left: 18rem;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  .el-button--primary{
    background: #4FBBEF;
    border:none;
  }
  .el-button{
    width: 80%;
    height: 2.8rem;
    font-size: 1.1rem;
  }
}
.icon{
  padding-left: 18rem;
  width: 20rem;
  margin: auto;
  display: flex;
  justify-content: space-around;
  padding-top: 2rem;
  img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
}
.a{
  cursor: pointer;
}
::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
  color: #606266;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color:#4FBBEF;
  border-color:#4FBBEF;
}
.wxdown{
  font-size: 0.9rem;
  font-weight: bold;
  color: #444444;
  position: relative;
  top: -5rem;
  left: 0;
}

</style>