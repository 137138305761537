<template>
  <div>
    <el-dialog
      :visible.sync="config.isshow"
      :modal-append-to-body='false'
      width="20%"
      center>
      <div class="flex justify-content-center align-items-center">
        <el-image :src="url"></el-image>
      </div>
      <div>
        <span>扫码下载手机端</span>
      </div>
    </el-dialog>
  </div>
 
</template>

<script>
import { pcwebconfig } from "../api/introduce";

export default {
  props:{
    config:{
      type:Object,
      default:{
        isshow:false
      },
    }
  },
  data() {
    return {
      url:''
    };
  },
  created(){
    this.getinfo()
  },
  methods: {
    getinfo(){
      pcwebconfig().then(res => {
        if(res.code == 1){
          this.url = res.data.appdownload.value
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  border-radius: 1.1rem;
  height: 25rem;
  border-radius: 1rem;
  background-image: url(../assets/downBg.png);
  background-size: 100% 100%;
  position: relative;
}
.el-image{
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 6rem;
}
span{
  font-size: 1.3rem;
  color: #4FBBEF;
  position: absolute;
  top: 20.7rem;
  left: 7rem;
}
</style>