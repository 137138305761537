import axios from 'axios'
import { Notification, Message } from 'element-ui' //引入element-ui库
import store from '@/store';
import w_md5 from './w_md5.js'
// 创建axios实例
const service = axios.create({
    baseURL: 'https://dslw.dsecc.cn', // 设置请求的基本URL
    timeout: 5000, // 请求超时时间
    withCredentials: false, //表示跨域请求时是否需要使用凭证
})

service.interceptors.request.use(
    config => {
        const token = store.getters.getToken;
        config.headers = {
          'Token':token,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        let nowtime = new Date().getTime()
        if(config.data){
          let arr = Object.keys(config.data)
          if (arr.length > 0) {
              for (let i = 0; i < arr.length; i++) {
                  config.data[arr[i]] = String(config.data[arr[i]])
              }
              config.data.time = String(nowtime)
              config.data.sign = w_md5.hex_md5_32(w_md5.hex_md5_32(JSON.stringify(config.data)) + String(nowtime) + '147258369963852741')
          }
        }
      
      return config
    },
    error => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(response=>{
    const resp = response.data
    if(response.request.responseType == "blob"){
        return response
    }
    return resp
},
error => {
    return Promise.reject(error)
})

export default service;