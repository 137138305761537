<template>
  <el-footer>
    <div class="row">
      <div class="left">
        <el-image style="width: 180px;margin-bottom: 1rem;" src="http://dslw.oss-cn-beijing.aliyuncs.com/web/logo.png" fit="contain"></el-image>
        <div class="codebox">
          <div class="code">
            <img :src="info && info.appdownload.value" style="width: 70px; height: 70px">
            <span>扫码下载APP</span>
          </div>
          <div class="code">
            <img :src="info && info.officialqrcode.value" style="width: 70px; height: 70px">
            <span>扫码关注公众号</span>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="work">
          <span @click="$router.push({path:'/findWork'})">找工作</span>
          <span @click="$router.push({path:'/recruit'})" style="padding-top: 1.5rem;">招人才</span>  
          <span @click="$router.push({path:'/work'})" style="padding-top: 1.5rem;">记工记账</span>
          <span @click="$router.push({path:'/clouding'})" style="padding-top: 1.5rem;">云培训</span>
        </div>
        <div class="work">
          <span @click="$router.push({path:'/tender'})">招标信息</span>
          <span @click="$router.push({path:'/news'})" style="padding-top: 1.5rem;">新闻资讯</span>
          <span @click="$router.push({path:'/introduce'})" style="padding-top: 1.5rem;">企业介绍</span>
          <span @click="$router.push({path:'/download'})" style="padding-top: 1.5rem;">下载APP</span>
        </div>
      </div>
      <div class="right">
        <div class="content">
          <img src="../assets/femail.png" alt="">
          <div><span>服务邮箱：</span>{{info && info.cemail.value }}</div>
        </div>
        <div class="content" style="padding-top: 2.7rem;">
          <img src="../assets/faddress.png" alt="">
          <div><span>详细地址：</span>{{ info && info.caddress.value }}</div>
        </div>
        <div class="content" style="padding-top: 2.7rem;">
          <img src="../assets/fphone.png" alt="">
          <div><span>服务电话：</span>{{ info && info.ctel.value }}</div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <p>{{ info && info.beian.value }}</p>
  </el-footer>
</template>
   
<script>
import { pcwebconfig } from "../api/introduce";

export default {
  data() {
    return {
      info:''
    };
  },
  created(){
    this.getinfo()
  },
  methods:{
    getinfo(){
      pcwebconfig().then(res => {
        if(res.code == 1){
          this.info = res.data
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.el-footer{
  width: 80%;
  margin: auto;
  .row{
    padding-top: 2rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .left{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 9rem;
    .codebox{
      display: flex;
      justify-content: space-between;
      .code{
        display: flex;
        flex-direction: column;
        align-items: center;
        span{
          padding-top: 0.3rem;
        }
      }
      .code:nth-of-type(2){
        padding-left: 2rem;
      }
    }
  }
  .center{
    display: flex;
    justify-content: space-around;
    padding-right: 9rem;
    .work{
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
    .work:nth-of-type(2){
        padding-left: 4rem;
      }
  }
  .right{
    font-size: 0.8rem;
    .content{
      display: flex;
      align-items: center;
      img{
        width: 1.6rem;
        height: 1.6rem;
      }
      div{
        color: #6A6A6A;
        padding-left: 0.5rem;
        font-size: 0.9rem;
      }
        i{
          font-size: 1.3rem;
        }
        
    }
  }
  }
  p{
    width: 90%;
    display: flex;
    justify-content: center;
    color: #6A6A6A;
    padding-bottom: 2rem;
  }
}

</style>