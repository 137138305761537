<template>
	<el-header>
		<el-image src="http://dslw.oss-cn-beijing.aliyuncs.com/web/logo.png" fit="contain" @click="$router.push({path:'/'})"></el-image>
    <div class="location" @click="$router.push({path:'/home/changeCity'})">
      <i class="el-icon-location" style="color: #4FBAEF;font-size: 1.3rem;"></i>
      <div style="line-height: 1.8;">{{ cityName }}</div>
      <span>[切换城市]</span>
    </div>
    <div class="nav">
      <router-link :to="{name:'home'}" :class="{'active':isRouteActive('home')}" class="custom-link">首页</router-link>
      <router-link :to="{name:'findWork'}" :class="{'active':isRouteActive('findWork')}" class="custom-link">找工作</router-link>
      <router-link :to="{name:'recruit'}" :class="{'active':isRouteActive('recruit')}" class="custom-link">招人才</router-link>
      <router-link :to="{name:'work'}" :class="{'active':isRouteActive('work')}" class="custom-link">记工记账</router-link>
      <router-link :to="{name:'clouding'}" :class="{'active':isRouteActive('clouding')}" class="custom-link">云培训</router-link>
      <router-link :to="{name:'tender'}" :class="{'active':isRouteActive('tender')}" class="custom-link">招标信息</router-link>
      <router-link :to="{name:'news'}" :class="{'active':isRouteActive('news')}" class="custom-link">新闻资讯</router-link>
      <router-link :to="{name:'introduce'}" :class="{'active':isRouteActive('introduce')}" class="custom-link">企业介绍</router-link>
      <router-link :to="{name:'download'}" :class="{'active':isRouteActive('download')}" class="custom-link">下载APP</router-link>
    </div>
    <!-- 登录/注册 -->
    <div v-if="!user">
      <el-link :underline="false" @click="tologin">登录/注册</el-link>
    </div>
    <!-- 个人中心 -->
    <div v-if="user" @mouseenter="show = true" class="select">
      <div class="flex align-items-center">
        <el-avatar :size="30" :src="avatar"></el-avatar>
        <div class="p-l-5">{{ username }}</div>
      </div>
      <div class="position flex flex-column justify-content-around align-items-center" v-if="show == true" @mouseleave="show = false">
        <div @click="$router.push({path:'/my'})">个人中心</div>
        <div @click="loginout">退出</div>
      </div>
    </div>
    <viewlogin :config="isconfig" ref="login" @login-success="handleLoginSuccess"></viewlogin>
	</el-header>
</template>
   
<script>
import viewlogin from './login.vue'
import { getcity ,index } from '@/api/my';

export default {
  components:{viewlogin},
	data() {
		return {
      isconfig:{
        isshow:false,
      },
      activeIndex:'1',
      login:false,
      show:false,
      token:'',
      avatar:'',
      username:''
		}
	},
  computed:{
    user(){
      return this.$store.getters.getUserInfo;
    },
    cityName(){
      return this.$store.getters.getCityData.name
    }
  },
  created(){
    // console.log(this.$store.getters.getUserInfo)
    if(this.$store.getters.getUserInfo != null){
      this.getuser()
    }
  },
  mounted() {
    this.getCity()
  },
  methods: {
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
    handleLoginSuccess(data) {
      this.username = data.name
      this.avatar = data.avatar
    },
    getuser(){
      index().then(res => {
        if(res.code == 1){
          this.token = res.data.token
          this.avatar = res.data.avatar
          this.username = res.data.username
        }
      })
    },
    getCity(){
      let geo = new BMap.Geolocation();
      geo.getCurrentPosition((res=>{
          getcity({
            longitude:res.longitude,
            latitude:res.latitude
          }).then(ress =>{
            if(ress.code == 1){
              let data = {
                id: ress.data.city_id,
                name: res.address.city
              }
              this.$store.dispatch('changeCity', data);
            }
          })
      }))
    },
    tologin(){
      this.isconfig.isshow = true
      // this.$refs.login.clearValues()
    },
    loginout(){
      localStorage.removeItem('loginpc')
      this.$store.dispatch('logout');
      this.$router.push({path:'/'})
    }
  },
}
</script>
<style scoped lang="scss">
@import "../style/padding";
@import "../style/flex";


.el-header{
  display: flex; 
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  .el-image{
    cursor: pointer;
    width: 7.5rem;
  }
}
.location{
  display: flex; 
  align-items: center;
  justify-content: center;
  margin: 0 2rem 0 1rem;
  span{
    font-size: 0.8rem;
    color: #4FBAEF;
    cursor: pointer;
  }
}
.nav{
  width: 40%;
  display: flex;
  justify-content: space-between;
  padding-right: 1.5rem;
  cursor: pointer;
}
.select{
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  .position{
    width: 8rem;
    height: 6rem;
    background: #fff;
    position: absolute;
    left: -1rem;
    top: 2.5rem;
    font-size: 0.9rem;
    z-index:9999 !important;
  }
}
.active {
   color: #4FBBEF !important;
 }
.custom-link {
  text-decoration: none;
  color: #333;
}
// .router-link-exact-active, .router-link-active {
//   color: #4FBBEF; 
// }
</style>