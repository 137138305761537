<template>
  	<div id="app">
    	<view-header></view-header>
      <viewAside class="aside"></viewAside>
      <router-view></router-view>
      <view-footer class="foot"></view-footer>
      <viewlogin :config="isconfig"></viewlogin>
  	</div>
</template>

<script>

import viewHeader from './components/header.vue'
import viewFooter from './components/footer.vue'
import viewAside from './components/aside.vue'
import viewlogin from './components/login.vue'


export default {
  	name: 'App',
	components:{
		viewHeader,
		viewFooter,
    viewAside,
    viewlogin
	}, 
  data(){
    return{
      isconfig:{  
        isshow:false,
      }
    }
   
  },
  watch:{
    $route:{
      handler(){
        if (window.location.href.indexOf('code') >= 0) {
            let url = window.location.href.split('?')[1];
            let codeUrl = url.split('&')[0]
            let code = codeUrl.split('=')[1]
            // let dataUrl = url.split('&')[1]
            // if(url.split('&')[1]){
            //   let data = dataUrl.split('=')[1]
            //   console.log(data)
            // }
            if(code == 1 || code == 7 || code == 13){
              this.isconfig.isshow = true
            } 
        }
      },
      deep: true
    }
  }

}
</script>

<style>
body{
	margin: 0;
}
html{
	font-size: 16px;
	font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	color: #444444;
}
.aside{
  position: fixed;
  bottom: 10rem;
  right: 2rem; 
  z-index: 999
}
</style>
