<template>
  <div class="flex flex-column align-items-center ">
    <div class="app flex flex-column align-items-center" @click="downApp">
      <img src="../assets/app.png" alt="">
      <div>下载手机端</div>
    </div>
    <div class="top">
      <img src="../assets/top.png" alt="" @click="scrollToTop">
    </div>
    <viewapp :config="isshow"></viewapp>
    <viewlogin :config="isconfig" ref="login"></viewlogin>
  </div>
</template>

<script>
import viewapp from './downapp.vue'
import viewlogin from './login.vue'

  export default {
    components:{viewapp,viewlogin},
    data(){
      return{
        isshow:{  //下载手机端
          isshow:false,
        },
        isconfig:{  //登录
          isshow:false,
        },
      }
    },
    computed:{
      userlogin(){
        return this.$store.getters.getUserInfo;
      },
    },
    methods: {
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth" 
        });
      },
      downApp(){
        if(!this.userlogin){
          this.isconfig.isshow = true
        }else{
          this.isshow.isshow = true
        }
      }
    }
  }
</script>

<style scoped lang="scss">
img{
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
}
.app{
  padding-bottom: 2rem;
  div{
    font-size: 0.9rem;
  }
}

</style>