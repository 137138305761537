import request from '../utils/request'

export function index(data) {  
	return request({
		url: '/api/user/index',
		method: 'post',
		data,
	})
}

//个人资料
export function updatemy(data) {  //保存
	return request({
		url: '/web/user/updatemy',
		method: 'post',
		data,
	})
}

//联系记录
export function usercall(data) {  
	return request({
		url: '/api/user/usercall',
		method: 'post',
		data,
	})
}

//管理招工
export function workmy(data) {  //我的招工
	return request({
		url: '/api/look_job/workmy',
		method: 'post',
		data,
	})
}

export function offshelf(data) {  //关闭招工
	return request({
		url: '/api/look_job/offshelf',
		method: 'post',
		data,
	})
}

export function republish(data) {  // 重新发布
	return request({
		url: '/api/look_job/republish',
		method: 'post',
		data,
	})
}

export function deljob(data) {  // 删除招工
	return request({
		url: '/api/look_job/deljob',
		method: 'post',
		data,
	})
}

//我的名片
export function mycard(data) {  // 我的名片
	return request({
		url: '/api/user/mycard',
		method: 'post',
		data,
	})
}

export function workstatus(data) {  // 修改工作状态
	return request({
		url: '/api/user/workstatus',
		method: 'post',
		data,
	})
}

export function voskills(data) {  //职业技能修改
	return request({
		url: '/api/user/voskills',
		method: 'post',
		data,
	})
}

export function myintroduction(data) {  //自我介绍修改
	return request({
		url: '/api/user/myintroduction',
		method: 'post',
		data,
	})
}

export function projectlist(data) {  //项目经验列表
	return request({
		url: '/web/user/projectlist',
		method: 'post',
		data,
	})
}

export function myproject(data) {  //添加项目经验
	return request({
		url: '/api/user/myproject',
		method: 'post',
		data,
	})
}

export function delproject(data) {  //删除项目经验
	return request({
		url: '/api/user/delproject',
		method: 'post',
		data,
	})
}

export function nation(data) {  //民族
	return request({
		url: '/api/index/nation',
		method: 'post',
		data,
	})
}

export function allarea(data) {  //城市三级联动
	return request({
		url: '/web/user/allarea',
		method: 'post',
		data,
	})
}

export function workalldata(data) {  // 所有工种
	return request({
		url: '/web/work/workalldata',
		method: 'post',
		data,
	})
}

export function userworkconfig(data) {  //配置信息
	return request({
		url: '/web/user/userworkconfig',
		method: 'post',
		data,
	})
}

export function myinfo(data) {  //修改简历信息
	return request({
		url: '/api/user/myinfo',
		method: 'post',
		data,
	})
}

export function getcity(data) {  //经纬度
	return request({
		url: '/api/third/getcity',
		method: 'post',
		data,
	})
}

//谁看过我
export function lookworkmy(data) {  //谁看过我的招工
	return request({
		url: '/api/look_job/workmy',
		method: 'post',
		data,
	})
}

export function cardlook(data) {  //谁看过我的简历
	return request({
		url: '/api/user/cardlook',
		method: 'post',
		data,
	})
}

export function lookpeople(data) {  //谁看过我的招工详情
	return request({
		url: '/api/look_job/lookpeople',
		method: 'post',
		data,
	})
}

export function peoplework(data) {  //谁看过我的简历详情
	return request({
		url: '/api/user/peoplework',
		method: 'post',
		data,
	})
}

//发布招工信息
export function workconfignew(data) {  //发布工种的配置接口
	return request({
		url: '/api/work/workconfignew',
		method: 'post',
		data,
	})
}

export function release(data) {  //发布招工信息
	return request({
		url: '/api/work/release',
		method: 'post',
		data,
	})
}

export function workmydetail(data) {  //修改招工
	return request({
		url: '/api/look_job/workmydetail',
		method: 'post',
		data,
	})
}

//帮助中心
export function questionlist(data) {  //帮助中心
	return request({
		url: '/api/accounting/questionlist',
		method: 'post',
		data,
	})
}

//认证管理
export function cardinfo(data) {  //识别信息
	return request({
		url: '/api/user/cardinfo',
		method: 'post',
		data,
	})
}

export function subcardinfo(data) {  //提交上传
	return request({
		url: '/api/user/subcardinfo',
		method: 'post',
		data,
	})
}